import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelectedCompany } from '../../hooks';

import { hasAllOperationalDocuments, operationalDocuments } from '../../helpers';
import { formatText } from '../../helpers/text-parser';
import UploadDocumentsForm from './UploadDocumentsForm';

const UploadOperationalDocumentsForm = () => {
  const company = useSelectedCompany();
  const hasAllRequiredDocuments = useMemo(
    () => company && hasAllOperationalDocuments(company),
    [company],
  );

  const getSubtitle = () => {
    if (hasAllRequiredDocuments) {
      return 'Ya tienes todos los documentos necesarios';
    }
    return 'Te pedimos estos documentos para tu operación con Fingo:';
  };

  const subtitle = useMemo(() => getSubtitle(), [hasAllRequiredDocuments]);

  return (
    <>
      <Typography variant="h2" align="center" sx={{ mb: 2, px: 0 }}>
        {formatText(subtitle)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <UploadDocumentsForm requiredDocuments={operationalDocuments} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadOperationalDocumentsForm;
